import React from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/Container";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "./gallery.css";

import gallery1 from "../gallery/Galleryimage/gallery1.jpg";
import gallery2 from "../gallery/Galleryimage/galllery2.jpg";
import gallery3 from "../gallery/Galleryimage/gallery3.jpg";
import gallery4 from "../gallery/Galleryimage/gallery4.jpeg";
import gallery5 from "../gallery/Galleryimage/gallery5.jpeg";
import gallery6 from "../gallery/Galleryimage/gallery6.jpg";
import gallery7 from "../gallery/Galleryimage/gallery7.jpeg";
import gallery8 from "../gallery/Galleryimage/gallery8.jpeg";
import gallery9 from "../gallery/Galleryimage/gallery9.jpeg";

const Gallery = () => {
  return (
    <>
      <hr></hr>
      <section className="mt-4 pt-4" id="gallery">
        <Container>
          <Row className="text-center">
            <h2 className="mt-4 mb-4 pb-4">Gallery</h2>
          </Row>
          <Row>
            <Col>
              <img src={gallery1} className="gallery-img" />
            </Col>
            <Col>
              <img src={gallery2} className="gallery-img" />
            </Col>
            <Col>
              <img src={gallery3} className="gallery-img" />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <img src={gallery4} className="gallery-img" />
            </Col>
            <Col>
              <img src={gallery5} className="gallery-img" />
            </Col>
            <Col>
              <img src={gallery6} className="gallery-img" />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <img src={gallery7} className="gallery-img" />
            </Col>
            <Col>
              <img src={gallery8} className="gallery-img" />
            </Col>
            <Col>
              <img src={gallery9} className="gallery-img" />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Gallery;
