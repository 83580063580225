import React from "react";
import Common from "../../layouts/Common";
import Navtop from "../../common/Navtop";
import Footer from "../../common/Footer";
import { ScrollLink } from "react-scroll";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import pacificlogo from "../image/pacificlogo.jpg";
import "./Admission.css";
const Admission = () => {
  return (
    <>
      <Navbar
        expand="lg"
        className="customHeader"
        style={{
          position: " fixed !important",
          zIndex: "9",
          width: "100%",
          padding: "12px 0 !important",
          backGround: "white",
          boxShadow: "2px 3px 7px 1px",
        }}
      >
        <Container>
          <Row>
            <Col md={2} className="nav-grid-cutom">
              <h2 className="navLogo pt-2">
                <NavLink className="logohideone" exact to="/home">
                  <img src={pacificlogo} alt="logo" />
                </NavLink>
              </h2>
            </Col>
            <Col
              md={10}
              className="nav-grid-cutom align-self-center d-flex justify-content-center"
            >
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className=" custom-navbar">
                  <Link to="/#">
                    <FaHome className="me-1 home-mar" />
                    Home
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Col>
          </Row>
        </Container>
      </Navbar>{" "}
      <section className="admission-section p-4">
        <Container>
          <Row className="mt-4 pt-4">
            <h2 className="text-center">Japanese Language Training Course</h2>
            <Col sm={12} md={6}>
              <div className="admission-feature services-card-border">
                <h3 className="text-center">N4</h3>
                <div>
                  <h4>
                    <strong>Duration:</strong> 4 Months (Book HandOut Free)
                  </h4>
                  <h4>
                    <strong>Course Fee:</strong> 15000tk
                  </h4>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className="admission-feature services-card-border">
                <h3 className="text-center">N5</h3>
                <div>
                  <h4>
                    <strong>Duration:</strong> 4 Months (Book HandOut Free)
                  </h4>
                  <h4>
                    <strong>Course Fee:</strong> 15000tk
                  </h4>
                </div>
              </div>{" "}
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default Admission;
