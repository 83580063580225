import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollTop";
import Aboutus from "./pages/about/Aboutus";
import Gallery from "./pages/gallery/gallery";
import Home from "./pages/home/Home";

import Services from "./pages/service/Services";

import ContactUs from "./pages/ContactUs/ContactUs";
import Admission from "./pages/Admission/Admission";
const Routing = () => {
  return (
    <HashRouter hashType="noslash">
      <ScrollToTop />
      <Routes>
        <Route>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />

          <Route path="gallery" element={<Gallery />} />
          <Route path="contact-us" element={<ContactUs />} />

          <Route path="service" element={<Services />} />
          <Route path="about-us" element={<Aboutus />} />
          <Route path="admission" element={<Admission />} />
        </Route>
      </Routes>
    </HashRouter>
  );
};

export default Routing;
