import React, { useState } from "react";

import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

import { Container } from "react-bootstrap";

import { FaLanguage } from "react-icons/fa";
import { FaPassport } from "react-icons/fa";
import { MdWork } from "react-icons/md";

const Services = () => {
  return (
    <section className="mb-4 pb-4" id="services">
      <Container>
        <Row>
          <Col md={12} className="text-center pt-4 mt-4">
            <h2>Our Services</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={4} sm={12}>
            <div className="company-feature services-card-border">
              {" "}
              <span>
                <FaLanguage style={{ color: "#ff0b09" }} />
              </span>
              <h5 className="text-center mt-5">Japanese Language Training</h5>
              <p>
                {" "}
                We provide Japanese language training for N4 and N5 level. Our
                experienced instructor has vast experience in Japanese language
                teaching.{" "}
              </p>
            </div>
          </Col>
          <Col lg={4} sm={12}>
            <div className="company-feature">
              {" "}
              <span>
                <FaPassport style={{ color: "#ff0b09" }} />
              </span>
              <h5 className="text-center mt-5">
                Japanese Student Visa Processing
              </h5>
              <p style={{ width: "106%" }}>
                {" "}
                We provide services to aspiring students who want to study in
                Japan. Our dedicated experienced team assists the candidate to
                have a smooth journey in acquiring student visa from the embassy
                of Japan.
              </p>
            </div>
          </Col>
          <Col lg={4} sm={12}>
            <div className="company-feature">
              {" "}
              <span>
                <MdWork style={{ color: "#ff0b09" }} />
              </span>
              <h5 className="text-center mt-5">
                Japanese Work Visa Processing
              </h5>
              <p>
                {" "}
                We assist the prospering candidates with SSW visas. Our team
                works with candidates from language training to arrival in
                Japan.{" "}
              </p>
            </div>
          </Col>
        </Row>

        {/* <Row>
      <Col md={12} className="text-center mt-4">
        <Button
          variant="primary btn-custom mt-3 btn-big w260"
          onClick={handleShow}
        >
          Get a Free Quote
          <FaArrowRight className="ms-5" />
        </Button>
      </Col>
    </Row> */}
      </Container>
    </section>
  );
};

export default Services;
