import React from "react";
import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/Container";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import pacificlogo from "../image/pacificlogo.jpg";
import "./Aboutus.css";

const Aboutus = () => {
  const options3 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 3,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <>
      <hr></hr>
      <section id="aboutus" className="mt-4 pt-4">
        <Container>
          <Row>
            <Col md={6} className="mb-4 align-self-center">
              <h2 className="mt-4">About us</h2>
              <p>
                We started our journey in 2008, by opening our first office in
                Japan with a hope to assist the bengali aspiring students to
                fulfil their dream to study and settle in Japan. Since the
                beginning we have advised and assisted more than 700 students to
                come to Japan for study purposes. In 2014, we opened our first
                office in the heart of Dhaka, which is the capital city of
                Bangladesh. Our language training journey started since we are
                operating in Dhaka. Pacific Academy has trained more than 1000
                students for Japanese language training. At the present time, we
                have 9 branch offices all over Bangladesh.
              </p>
            </Col>
            <Col md={6} className=" mb-4">
              <img
                className="abouimg mt-4"
                src={pacificlogo}
                alt="about"
                style={{ boxShadow: " 2px 2px 10px 2px black" }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Aboutus;
