import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import as from "../../assets/images/as.svg";
import bs from "../../assets/images/bs.svg";
import rightimfe from "../../assets/images/rightimfe.svg";
import { NavLink } from "react-router-dom";

const VisionMission = () => {
  return (
    <>
      <hr></hr>
      <section className=" pt-3 mb-5" id="vision">
        <Container>
          <Row>
            <Col md={6} className="mt-4 mb-4">
              <div
                className="company-slide mission-vission"
                data-aos="flip-left"
              >
                <Row>
                  <Col md={4}>
                    <div className="slider-icon">
                      {" "}
                      <img src={as} alt="as" />{" "}
                    </div>
                  </Col>
                  <Col md={8} className="ps-0">
                    <h5 className="p-0">Our Vision</h5>
                    <p className="mb-0">
                      {" "}
                      Bringing professional steps to be the very best in the
                      industry.{" "}
                    </p>
                  </Col>
                </Row>
              </div>
              <div
                className="company-slide mission-vission mt-4"
                data-aos="flip-right"
              >
                <Row>
                  <Col md={8} className="pe-0 text-end">
                    <h5 className="p-0">Our Mission</h5>
                    <p className="mb-0">
                      {" "}
                      Our Mission Bringing positive outcomes and fulfilling
                      people's dreams.{" "}
                    </p>
                  </Col>
                  <Col md={4}>
                    <div className="slider-icon">
                      {" "}
                      <img src={bs} alt="bs" />{" "}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <div className="company-block-feature-two position-img">
                {" "}
                <img src={rightimfe} alt="rightimfe" />{" "}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default VisionMission;
