import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { FaHome } from "react-icons/fa";
import { NavLink } from "react-router-dom";

import "./Nav.css";
import { HashLink as Link } from "react-router-hash-link";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import pacificlogo from "../pages/image/pacificlogo.jpg";
import { StickyNav } from "react-sticky-nav";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const Navtop = () => {
  const [stickyClass, setStickyClass] = useState();

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 150 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };
  return (
    <div>
      <Navbar className={`customHeader ${stickyClass}`} expand="lg">
        <Container>
          <Row>
            <Col md={2} className="nav-grid-cutom">
              <h2 className="navLogo pt-2">
                <NavLink className="logohideone" exact to="/home">
                  <img src={pacificlogo} alt="logo" />
                </NavLink>
              </h2>
            </Col>
            <Col md={10} className="nav-grid-cutom align-self-center">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className=" custom-navbar">
                  <Link to="/#">
                    <FaHome className="me-1 home-mar" />
                    Home
                  </Link>

                  <ScrollLink
                    style={{ cursor: "pointer" }}
                    to="services"
                    smooth={true}
                    spy={true}
                    offset={-160}
                    duration={500}
                    activeClass="active"
                  >
                    Services
                  </ScrollLink>
                  <ScrollLink
                    style={{ cursor: "pointer" }}
                    to="aboutus"
                    smooth={true}
                    spy={true}
                    offset={-95}
                    duration={500}
                    activeClass="active"
                  >
                    About Us
                  </ScrollLink>
                  {/* <ScrollLink
                    to="experties"
                    smooth={true}
                    spy={true}
                    offset={-80}
                    duration={500}
                    activeClass="active"
                  >
                    Experiecnce
                  </ScrollLink> */}
                  {/* <ScrollLink
                    to="vision"
                    smooth={true}
                    spy={true}
                    offset={-100}
                    duration={500}
                    activeClass="active"
                  >
                    Vision & Mission
                  </ScrollLink> */}

                  <ScrollLink
                    style={{ cursor: "pointer" }}
                    to="gallery"
                    smooth={true}
                    spy={true}
                    duration={500}
                    activeClass="active"
                  >
                    Gallery
                  </ScrollLink>

                  <NavLink exact to="/admission">
                    {" "}
                    Admission
                  </NavLink>

                  <ScrollLink
                    style={{ cursor: "pointer" }}
                    to="contactUs"
                    smooth={true}
                    spy={true}
                    duration={500}
                    activeClass="active"
                    offset={-650}
                  >
                    Contact Us
                  </ScrollLink>

                  {/* <NavLink className="activenone" exact to="/contact">
                    <Col md={2} className="navButton dis-hide-show">
                      <Button variant="primary btn-custom mt-1 ps-4 pe-4">
                        Get In Touch
                      </Button>
                    </Col>
                  </NavLink> */}
                </Nav>
              </Navbar.Collapse>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navtop;
