import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "./IndustryExperties.css";
import { MdWork } from "react-icons/md";
// <MdWork />
import { MdPerson } from "react-icons/md";
// <GrUserManager />
import { IoIosDocument } from "react-icons/io";
// <IoIosDocument />
import { FaLanguage } from "react-icons/fa";
// <FaLanguage />

import { FaPassport } from "react-icons/fa";
// <FaPassport />

import { FaHouseUser } from "react-icons/fa";
// <FaHouseUser />

const IndustryExperties = () => {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };
  return (
    <>
      <hr></hr>
      <section
        id="experties"
        style={{ paddingTop: "110px", marginTop: "-40px" }}
      >
        <Container>
          <Row>
            <Col md={12} className="text-start mb-4">
              <h2>Our Experties and Experience</h2>
            </Col>
          </Row>
          <Row>
            <OwlCarousel
              className="owl-theme slider-custom custom-industry"
              {...options}
              loop
              margin={20}
              nav
            >
              <div class="item">
                <div className="company-slide">
                  <div className="slider-icon">
                    {" "}
                    {/* <img src={manufacturing} alt="manufacturing" />{" "} */}
                    <FaLanguage style={{ fontSize: "4em", color: " white " }} />
                  </div>
                  <h5>
                    Japanese Language <br></br>Training
                  </h5>
                </div>
              </div>
              <div class="item">
                <div className="company-slide bg3">
                  <div className="slider-icon">
                    {" "}
                    <MdPerson style={{ fontSize: "4em", color: " white " }} />
                    {/* <img src={logistics} alt="logistics" />{" "} */}
                  </div>
                  <h5>
                    Japanese Sttudent <br></br>Visa Processing
                  </h5>
                </div>
              </div>
              <div class="item">
                <div className="company-slide bg1">
                  <div className="slider-icon">
                    {" "}
                    <MdWork style={{ fontSize: "4em", color: " white " }} />
                    {/* <img src={healthcare} alt="healthcare" />{" "} */}
                  </div>
                  <h5>
                    Japanese Job Visa <br></br>Processing
                  </h5>
                </div>
              </div>
              <div class="item">
                <div className="company-slide bg2">
                  <div className="slider-icon">
                    {" "}
                    <FaPassport style={{ fontSize: "4em", color: " white " }} />
                    {/* <img src={aviation} alt="aviation" />{" "} */}
                  </div>
                  <h5>Japanese Ambassy Facing</h5>
                </div>
              </div>

              <div class="item">
                <div className="company-slide">
                  <div className="slider-icon">
                    {" "}
                    <FaHouseUser
                      style={{ fontSize: "4em", color: " white " }}
                    />
                    {/* <img src={ecommerce} alt="ecommerce" />{" "} */}
                  </div>
                  <h5>
                    Accommodation <br></br> In japan
                  </h5>
                </div>
              </div>
              <div class="item">
                <div className="company-slide">
                  <div className="slider-icon">
                    {" "}
                    <IoIosDocument
                      style={{ fontSize: "4em", color: " white " }}
                    />
                    {/* <img src={ecommerce} alt="ecommerce" />{" "} */}
                  </div>
                  <h5>
                    Documentation <br></br>Support
                  </h5>
                </div>
              </div>
            </OwlCarousel>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default IndustryExperties;
