// eslint-disable-next-line
import React, { useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Carousel from "react-bootstrap/Carousel";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { FaArrowRight } from "react-icons/fa";
import { BrowserRouter } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";

// import getques from '../../assets/images/getques.svg';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Navtop from "../../common/Navtop";
import Footer from "../../common/Footer";

// importing componenet

import Branches from "./Branches";
import Counter from "./Counter";
import Services from "../service/Services";
import Aboutus from "../about/Aboutus";
import Gallery from "../gallery/gallery";
import IndustryExperties from "../IndustryExperties/IndustryExperties";
import ClientFeedBack from "../clientfeedback/ClientFeedBack";
import VisionMission from "../Visionmission/VisionMission";
import Bannerimage from "../gallery/Galleryimage/gallery5.jpeg";
import bannerimage2 from "../gallery/Galleryimage/galllery2.jpg";
import bannerimage3 from "../gallery/Galleryimage/gallery7.jpeg";

import { FaSchool } from "react-icons/fa";
import { FaLanguage } from "react-icons/fa";
import { FaPassport } from "react-icons/fa";

const Home = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const handleAdmissionClick = () => {
    // Navigate to the Admission component
    navigate("/admission");
  };

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  const options2 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  const options3 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 3,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <>
      {/* navtop section starts */}

      <Navtop />
      {/* navtop ends */}

      {/* <section className="banner-section">
        <Container>
          <Row>
            <Col md={12}>
              <div className="slider-banner slide-arrow">
                <Carousel>
                  <Carousel.Item>
                    <img src={bannerimage} />
                  </Carousel.Item>
                  <Carousel.Item></Carousel.Item>
                  <Carousel.Item></Carousel.Item>
                </Carousel>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* <div
        id="carouselExampleSlidesOnly"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-block w-100" src={bannerimage3} alt="First slide" />
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src={bannerimage2} alt="Second slide" />
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src={bannerimage} alt="Third slide" />
          </div>
        </div>
      </div> */}
      {/* <div
        id="carouselExampleControls"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-block w-100" src={bannerimage3} alt="First slide" />
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src={bannerimage2} alt="Second slide" />
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src={bannerimage} alt="Third slide" />
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#carouselExampleControls"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleControls"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div> */}

      <Carousel>
        <Carousel.Item>
          <img
            src={Bannerimage}
            style={{ width: "100%", maxHeight: "34rem", objectFit: "cover" }}
            alt="banner"
          />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={bannerimage2}
            style={{ width: "100%", maxHeight: "34rem", objectFit: "cover" }}
            alt="banner"
          />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={bannerimage3}
            style={{ width: "100%", maxHeight: "34rem", objectFit: "cover" }}
            alt="banner"
          />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      {/* services section starts here */}

      <Services />

      {/* about us section starts */}

      <Aboutus />

      {/* industry experties section starts */}
      <IndustryExperties />

      {/* <section className="p-4">
        <hr></hr>
        <Container>
          <Row>
            <Col className="text-center pb-4 pt-4">
              <h2>Click Below For Admission</h2>
              <button
                onClick={handleAdmissionClick}
                style={{
                  backgroundColor: "rgb(255, 11, 9)",
                  width: "30%",
                  border: "none",
                  padding: "10px 0",
                  borderRadius: "10px",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "17px",
                }}
              >
                Admission
              </button>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* vision mission  */}
      <VisionMission />

      {/* client feedback  */}
      <ClientFeedBack />

      {/* gallery starts */}
      <Gallery />
      {/* gallery ends */}

      <div className="clearfix"></div>
      <Container>
        <div className="comapny-status  mt-3">
          <Row>
            <h1 className="text-center">Our Success</h1>
            <Col md={12} className="text-center">
              <div className="company-status-block">
                <FaSchool style={{ fontSize: "4.5em", color: "#ff0b09 " }} />
                <h3 style={{ color: "#ff0b09" }}>
                  <Counter targetNumber={300} />
                </h3>
                <p style={{ color: "#ff0b09" }}>Japanese School Affiliation</p>
              </div>

              <div className="company-status-block">
                <FaLanguage style={{ fontSize: "4.5em", color: "#ff0b09 " }} />
                <h3 style={{ color: "#ff0b09" }}>
                  {" "}
                  <Counter targetNumber={1000} />
                </h3>
                <p style={{ color: "#ff0b09" }}>Language Training</p>
              </div>

              <div className="company-status-block">
                <FaPassport style={{ fontSize: "4.5em", color: "#ff0b09 " }} />
                <h3 style={{ color: "#ff0b09" }}>
                  {" "}
                  <Counter targetNumber={600} />
                </h3>
                <p style={{ color: "#ff0b09" }}>Student Visa</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Branches />

      <Footer />
    </>
  );
};

export default Home;
