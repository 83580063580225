import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import testimonialimg from "../clientfeedback/tesimonialimg/tesimonialimg.jpeg";
import avatar1 from "../clientfeedback/tesimonialimg/tesimonialavatar .jpeg";
import avatar3 from "../clientfeedback/tesimonialimg/tesimonialavatar3.jpeg";
import avatar2 from "../clientfeedback/tesimonialimg/tesimonialavatar2.jpeg";
import avatar4 from "../clientfeedback/tesimonialimg/tesimonialavatar4.jpeg";

const ClientFeedBack = () => {
  return (
    <div>
      <hr></hr>
      <section className=" mt-4 pt-4">
        <Container>
          <Row className="text-center">
            <h2 className="mb-0">Applicants' FEEDBACK</h2>
          </Row>
          <Row>
            <Col md={5} sm={12} className="text-center">
              <img className="img-client" src={testimonialimg} alt="hire" />
            </Col>
            <Col md={7} sm={12} className="align-self-center">
              <Row>
                <Col md={12}>{/* <h2>Testimonials</h2> */}</Col>
              </Row>
              <Carousel
                fade
                className="slider-custom slider-custom-two display-none-slide"
              >
                <Carousel.Item>
                  <Row>
                    <Col md={12}>
                      <div className="client-block">
                        <div className="client-top">
                          {" "}
                          <img
                            src={avatar1}
                            alt="avater"
                            className="client-av"
                          />
                          <div className="client-details">
                            <h6 className="mb-1">Md Alamin</h6>
                          </div>
                        </div>
                        <p className="detail-message">
                          {" "}
                          Pacific Academy has helped me tremendously in every
                          step of applying to a Japanese language school and
                          acquiring a student visa from the embassy.{" "}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item>
                  <Row>
                    <Col md={12}>
                      <div className="client-block">
                        <div className="client-top">
                          {" "}
                          <img
                            src={avatar2}
                            alt="avater"
                            className="client-av"
                          />
                          <div className="client-details">
                            <h6 className="mb-1">Md Ariful Islam</h6>
                          </div>
                        </div>
                        <p className="detail-message">
                          It is the best transparent agency known to me for
                          applying in Japan.{" "}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item>
                  <Row>
                    <Col md={12}>
                      <div className="client-block">
                        <div className="client-top">
                          {" "}
                          <img
                            src={avatar3}
                            alt="avater"
                            className="client-av"
                          />
                          <div className="client-details">
                            <h6 className="mb-1">Md Razib Hossain</h6>
                          </div>
                        </div>
                        <p className="detail-message">
                          Pacific Academy guided me accurately in the process of
                          applying for a Japanese student visa.{" "}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item>
                  <Row>
                    <Col md={12}>
                      <div className="client-block">
                        <div className="client-top">
                          {" "}
                          <img
                            src={avatar4}
                            alt="avater"
                            className="client-av"
                          />
                          <div className="client-details">
                            <h6 className="mb-1">Md Sakib Hasan </h6>
                          </div>
                        </div>
                        <p className="detail-message">
                          Best Japanese Language Training Centre in Bangladesh{" "}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default ClientFeedBack;
