import React from "react";
import Common from "../../layouts/Common";

const ContactUs = () => {
  return (
    <>
      <Common>
        <section className="all-header"></section>
      </Common>
    </>
  );
};

export default ContactUs;
