import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Button from "react-bootstrap/Button";
import "./Footer.css";
import logo2 from "../assets/images/logo2.svg";
import { NavLink } from "react-router-dom";
import { FaPeriscope } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import bd from "../assets/images/bd.svg";
import Modal from "react-bootstrap/Modal";
import BackToTop from "react-back-to-top-button";
import { FaFacebook } from "react-icons/fa";
import pacificlogo from "../pages/image/pacificlogo.jpg";

const Footer = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();

  return (
    <>
      <div className="footer-area" id="contactUs">
        <hr></hr>
        <section className="all-company-feature "></section>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
          <Modal.Body></Modal.Body>
        </Modal>

        <Container>
          <Row>
            <Col md={6}>
              <NavLink className="logohideone" exact to="/home">
                <img src={pacificlogo} alt="logo2" className="footerlogo" />
              </NavLink>
              <p className="text-black mt-3 jus-text">
                {" "}
                We started our journey in 2008, by opening our first office in
                Japan with a hope to assist the bengali aspiring students to
                fulfil their dream to study and settle in Japan. Since the
                beginning we have advised and assisted more than 700 students to
                come to Japan for study purposes.
              </p>
            </Col>

            <Col md={6} className="align-self-center">
              <h3>
                <img src={bd} alt="bd" className="flag" />
                Bangladesh
              </h3>
              <ul className="list-group list-inline company-list-link">
                <li>
                  {" "}
                  <span className="text-black">
                    <FaPeriscope style={{ color: "#ff0b09" }} />
                  </span>
                  <label className="text-black">
                    {" "}
                    Khan Plaza (7th Floor), Road -01, Mirpur-10, Dhaka{" "}
                  </label>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaMobileAlt style={{ color: "#ff0b09" }} />
                  </span>
                  <label className="text-black">
                    {" "}
                    01973252566 , 01401457991
                  </label>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaFacebook style={{ color: "#ff0b09" }} />
                  </span>
                  <label>
                    <a
                      href="https://www.facebook.com/pacificacademyofficial"
                      className="text-black link"
                    >
                      https://www.facebook.com/pacificacademyofficial
                    </a>{" "}
                  </label>
                </li>
              </ul>
            </Col>
          </Row>
          <hr className="hr mt-3 mb-3" />
          <Row className="footer-bottom">
            <Col md={6}>
              <p> © {currentYear} All Rights Reserved By Pacific Academy </p>
            </Col>
            <Col md={6}>
              <Col>
                <p>
                  <a
                    className="link"
                    style={{ color: "black" }}
                    href="https://www.swiftitltd.com/"
                  >
                    Design and Developed By Swift IT Ltd
                  </a>
                </p>
              </Col>
            </Col>
          </Row>
        </Container>
        <BackToTop
          showOnScrollDown
          showAt={1200}
          speed={1500}
          easing="easeInOutQuint"
        >
          <button className="back-to-top">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" />
            </svg>
          </button>
        </BackToTop>
      </div>
    </>
  );
};

export default Footer;
